import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, confirm, setOptions, Snackbar} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {BalloonEditor, Autosave, BlockQuote, BlockToolbar, Bold, Essentials, Italic, Paragraph, RemoveFormat, Subscript, Superscript, Underline, CodeBlock} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import '../styles/dashboardQuestion.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faArrowRotateRight, faTrashCan, faBoxTaped, faObjectsColumn, faGearCode, faPlusMinus, faArrowRightFromArc} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import DashboardQuestionSettings from "../dialogs/DashboardQuestionSettings";
import DashboardQuestionSave from "../dialogs/DashboardQuestionSave";
import DashboardQuestionUsers from "../dialogs/DashboardQuestionUsers";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardQuestion({width, configuration, Dialog, dialog, toast, system, locale, setModel, model, functions, text, setText, setToast, setMessages, Navigation, loading, messages, tabs, setSystem, setSnackbar, setWorkspace, workspace, navigation, teamtalk, setTeamtalk, Conversation, user}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [editorInstance, setEditorInstance] = useState(null);
    const broadcast = useRef(null);
    const [active, setActive] = useState(null);

    const ButtonDelete = () => {
        confirm({
            title: locale.dashboardQuestion.ae,
            message: locale.dashboardQuestion.af,
            okText: locale.dashboardQuestion.x,
            cancelText: locale.dashboardQuestion.ad,
            callback: (res) => {
                if (res) {
                    setToast({message: locale.dashboardQuestion.ag, color: 'info', display: 'bottom', duration: 1000});

                    const message = {
                        type: 'wss',
                        path: 'tab',
                        action: 'delete',
                        data: {
                            tabs: tabs
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonMessage = () => {
        setToast({message: locale.dashboardQuestion.v, color: 'primary', display: 'center', duration: false});

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'update',
            data: {
                text: text,
                model: model,
                functions: functions,
                teamtalk:  (!Empty(teamtalk) && teamtalk.id) ? {id: teamtalk.id, users: teamtalk.users} : null
            }
        };
        wsCall(message);
    }

    const ButtonRestart = () => {
        confirm({
            title: locale.dashboardQuestion.ab,
            message: locale.dashboardQuestion.ac,
            okText: locale.dashboardQuestion.aa,
            cancelText: locale.dashboardQuestion.ad,
            callback: (res) => {
                if (res) {

                    const message = {
                        type: 'wss',
                        path: 'conversation-reset',
                        action: 'update',
                        data: (!Empty(teamtalk) && teamtalk.id) ? {teamtalk: {id: teamtalk.id, users: teamtalk.users}} : {}
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonWorkspace = () => {
        if (navigation.card === 'workspace') {
            Navigation({card: 'close'});
        } else {
            Navigation({card: 'workspace'});
        }
    }

    const ButtonSystem = () => {
        setSystem(false);

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'get',
            data: {
                system: false
            }
        };
        wsCall(message);
    }

    const ButtonLeave = () => {
        if (!Empty(teamtalk) && teamtalk.users && teamtalk.users.length === 1) {
            confirm({
                title: locale.dashboardQuestion.m,
                message: locale.dashboardQuestion.n,
                okText: locale.dashboardQuestion.x,
                cancelText: locale.dashboardQuestion.ad,
                callback: (res) => {
                    if (res) {

                        const message = {
                            type: 'wss',
                            path: 'teamtalk-conversation',
                            action: 'delete',
                            data: {
                                teamtalk: {id: teamtalk.id, users: teamtalk.users}
                            }
                        };
                        wsCall(message);
                    }
                }
            });
        } else {
            confirm({
                title: locale.dashboardQuestion.r,
                message: locale.dashboardQuestion.s,
                okText: locale.dashboardQuestion.e,
                cancelText: locale.dashboardQuestion.ad,
                callback: (res) => {
                    if (res) {

                        const message = {
                            type: 'wss',
                            path: 'teamtalk-conversation-user',
                            action: 'delete',
                            data: {
                                teamtalk: {id: teamtalk.id, users: teamtalk.users}
                            }
                        };
                        wsCall(message);
                    }
                }
            });
        }
    }

    const Avatar = () => {
        const itemAvatar = configuration.mobi.personalities.find(item => item.value === teamtalk.personality);

        return "url(" + ((teamtalk.voice === 'nova' || teamtalk.voice === 'shimmer') ? itemAvatar.avatarFemale : itemAvatar.avatarMale) + ")";
    }

    const EditorReady = editor => {
        setEditorInstance(editor);
    };

    const EditorUpdate = data => {
        let content = "<p></p>";

        if (data) {
            content = data
        }
        setText(content);

        if (!Empty(teamtalk) && teamtalk.id && broadcast.current) {
            broadcast.current = null;

        } else if (!Empty(teamtalk) && teamtalk.id && !broadcast.current) {

            const message = {
                type: 'wss',
                path: 'teamtalk-question',
                action: 'broadcast',
                data: {
                    text: content,
                    teamtalk:  {id: teamtalk.id, users: teamtalk.users}
                }
            };
            wsCall(message);
        } else if (Empty(teamtalk)) {
            window.localStorage.text = content;

        }
    }

    const AvatarStyle = data => {
        if (data.type === 'text' && width > 768 && (!active || active !== data.user)) {
            return "cs-dashboard-question-avatar-text mbsc-align-center mbsc-txt-m mbsc-bold mbsc-txt-muted";
        } else if (data.type === 'text' && width > 768 && active && active === data.user) {
            return "cs-dashboard-question-avatar-text cs-dashboard-question-avatar-active mbsc-align-center mbsc-txt-m mbsc-bold mbsc-txt-muted";
        } else if (data.type === 'text' && width < 768 && (!active || active !== data.user)) {
            return "cs-dashboard-question-avatar-text-xs mbsc-align-center mbsc-txt-s mbsc-bold mbsc-txt-muted";
        } else if (data.type === 'text' && width < 768 && active && active === data.user) {
            return "cs-dashboard-question-avatar-text-xs cs-dashboard-question-avatar-active-xs cs-dashboard-question-avatar-active mbsc-align-center mbsc-txt-m mbsc-bold mbsc-txt-muted";
        } else if (data.type === 'avatar' && width > 768 && (!active || active !== data.user)) {
            return "cs-dashboard-question-avatar";
        } else if (data.type === 'avatar' && width > 768 && active && active === data.user) {
            return "cs-dashboard-question-avatar cs-dashboard-question-avatar-active";
        } else if (data.type === 'avatar' && width < 768 && (!active || active !== data.user)) {
            return "cs-dashboard-question-avatar-xs";
        } else if (data.type === 'avatar' && width < 768 && active && active === data.user) {
            return "cs-dashboard-question-avatar-xs cs-dashboard-question-avatar-active-xs";
        } else if (data.type === 'ai' && width > 768 && Empty(toast)) {
            return "cs-dashboard-question-avatar";
        } else if (data.type === 'ai' && width < 768 && Empty(toast)) {
            return "cs-dashboard-question-avatar-xs";
        } else if (data.type === 'ai' && width > 768 && !Empty(toast)) {
            return "cs-dashboard-question-avatar cs-dashboard-question-avatar-pulse";
        } else if (data.type === 'ai' && width < 768 && !Empty(toast)) {
            return "cs-dashboard-question-avatar-xs cs-dashboard-question-avatar-pulse-xs";
        }
    }

    const UserActive = data => {
        setActive(data);

        setTimeout(() => {
            setActive(null);

        }, 1000);
    };

    const NavTab = useCallback(data => {
        const message = {
            type: 'wss',
            path: 'tab',
            action: 'get',
            data: {
                id: data,
                tabs: tabs
            }
        };
        wsCall(message);
    }, [tabs, wsCall]);

    const UpdateConversationReset = useCallback(data => {
        if (data.toast) {
            setMessages([]);
            setText('<p></p>');
            setToast({message: locale.dashboardQuestion.z, color: 'info', display: 'bottom', duration: 1000});

            if (Empty(teamtalk)) {
                window.localStorage.text = '<p></p>';
            }
        }
    }, []);

    const UpdateConversation = useCallback(data => {
        if (data.messages) {
            setMessages(data.messages);
            setText('<p></p>');
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (data.error) {
            confirm({
                title: locale.dashboardQuestion.g,
                message: locale.dashboardQuestion.h,
                okText: locale.dashboardQuestion.i,
                cancelText: locale.dashboardQuestion.l,
                callback: (res) => {
                    if (res) {
                        setToast({message: locale.dashboardQuestion.u, color: 'primary', display: 'center', duration: false});

                        const message = {
                            type: 'wss',
                            path: 'conversation-error',
                            action: 'update',
                            data: (!Empty(teamtalk) && teamtalk.id) ? {teamtalk: {id: teamtalk.id, users: teamtalk.users}} : {}
                        };
                        wsCall(message);
                    }
                }
            });
        }
        Navigation({card: 'close'});
        window.scrollTo(0, 0);
    }, [Navigation, locale.dashboardQuestion.g, locale.dashboardQuestion.h, locale.dashboardQuestion.i, locale.dashboardQuestion.l, locale.dashboardQuestion.u]);

    const UpdateConversationError = useCallback(data => {
        if (data.messages) {
            setMessages(data.messages);
        }
    }, []);

    const DeleteTeamtalkConversation = useCallback(data => {
        if (data.toast) {
            setTeamtalk({});
            setToast({message: locale.dashboardQuestion.o, color: 'info', display: 'bottom', duration: 1000});

            Conversation();
        }
    }, [Conversation, locale.dashboardQuestion.o]);

    const DeleteTeamtalkConversationUser = useCallback(data => {
        if (data.conversation) {
            setTeamtalk({});
            setToast({message: locale.dashboardQuestion.p, color: 'info', display: 'bottom', duration: 1000});
            Conversation();
        }
        if (data.teamtalkUsers) {
            const item = {...teamtalk};
            item.users = data.teamtalkUsers;
            setTeamtalk(item);
            setToast({message: locale.dashboardQuestion.q, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [NavTab, teamtalk, Conversation]);

    const BroadcastTeamtalkQuestion = useCallback(data => {
        if (data.text && data.text !== text) {
            UserActive(data.user);
            broadcast.current = data.user;
            editorInstance.setData(data.text);
        }
    }, [editorInstance, text]);

    const BroadcastTeamtalkConversationUpdate = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardQuestion.v, color: 'primary', display: 'center', duration: false});
        }
    }, [locale.dashboardQuestion.v]);

    const PutConversationSettings = useCallback(data => {
        if (data.model) {
            setModel(data.model);
        }
        if (data.teamtalkData) {
            setTeamtalk(data.teamtalkData);
        }
        if (dialog.view === 'dashboardQuestionSettings') {
            Dialog({view: 'close'});
        }
        setToast({message: locale.dashboardQuestion.y, color: 'info', display: 'bottom', duration: 1000});
    }, [Dialog, dialog.view]);

    const UpdateTeamtalkConversationUser = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardQuestion.q, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.teamtalkUsers && data.teamtalkUsers.find(item => item.user === user.id)) {
            const item = {...teamtalk};
            item.users = data.teamtalkUsers;
            setTeamtalk(item);
        } else if (data.teamtalkUsers && !data.teamtalkUsers.find(item => item.user === user.id)) {
            setTeamtalk({});
            Conversation();
        }
    }, [locale.dashboardQuestion.q, Conversation, teamtalk, user.id]);

    useEffect(() => {
        if (wsResponse &&
            wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'update' &&
            wsResponse.path === 'teamtalk-conversation-user' &&
            !Empty(teamtalk) &&
            wsResponse.data.teamtalk &&
            teamtalk.id === wsResponse.data.teamtalk) {
            UpdateTeamtalkConversationUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateTeamtalkConversationUser, setWsResponse]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'put' &&
            wsResponse.path === 'conversation-settings' &&
            ((Empty(teamtalk) && !wsResponse.data.teamtalk) ||
                (!Empty(teamtalk) && wsResponse.data.teamtalk && teamtalk.id === wsResponse.data.teamtalk))) {
            PutConversationSettings(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutConversationSettings, setWsResponse, teamtalk]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'broadcast' &&
            wsResponse.path === 'teamtalk-conversation-update' &&
            !Empty(teamtalk) &&
            wsResponse.data.teamtalk &&
            teamtalk.id === wsResponse.data.teamtalk) {
            BroadcastTeamtalkConversationUpdate(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, BroadcastTeamtalkConversationUpdate, setWsResponse, teamtalk]);

    useEffect(() => {
        if (!Empty(wsResponse) &&
            wsResponse.action === 'broadcast' &&
            wsResponse.path === 'teamtalk-question' &&
            !Empty(teamtalk) &&
            wsResponse.data.teamtalk &&
            teamtalk.id === wsResponse.data.teamtalk) {
            BroadcastTeamtalkQuestion(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, BroadcastTeamtalkQuestion, setWsResponse, teamtalk]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'delete' &&
            wsResponse.path === 'teamtalk-conversation-user' &&
            !Empty(teamtalk) &&
            wsResponse.data.teamtalk &&
            teamtalk.id === wsResponse.data.teamtalk) {
            DeleteTeamtalkConversationUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteTeamtalkConversationUser, setWsResponse, teamtalk]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'delete' &&
            wsResponse.path === 'teamtalk-conversation' &&
            !Empty(teamtalk) &&
            wsResponse.data.teamtalk &&
            teamtalk.id === wsResponse.data.teamtalk) {
            DeleteTeamtalkConversation(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteTeamtalkConversation, setWsResponse, teamtalk]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'update' &&
            wsResponse.path === 'conversation-reset' &&
            ((Empty(teamtalk) && !wsResponse.data.teamtalk) ||
                (!Empty(teamtalk) && wsResponse.data.teamtalk && teamtalk.id === wsResponse.data.teamtalk))) {
            UpdateConversationReset(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationReset, setWsResponse, teamtalk]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'update' &&
            wsResponse.path === 'conversation' &&
            ((Empty(teamtalk) && !wsResponse.data.teamtalk) ||
                (!Empty(teamtalk) && wsResponse.data.teamtalk && teamtalk.id === wsResponse.data.teamtalk))) {
            UpdateConversation(wsResponse.data);
            setWsResponse({});
            setToast({});
            setSnackbar({});
        }
    }, [wsResponse, UpdateConversation, setWsResponse, teamtalk]);

    useEffect(() => {
        if (wsResponse &&
            !Empty(wsResponse) &&
            wsResponse.action === 'update' &&
            wsResponse.path === 'conversation-error' &&
            ((Empty(teamtalk) && !wsResponse.data.teamtalk) ||
                (!Empty(teamtalk) && wsResponse.data.teamtalk && teamtalk.id === wsResponse.data.teamtalk))) {
            UpdateConversationError(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, UpdateConversationError, setWsResponse, teamtalk]);

    return (
        <>
            <div className="mbsc-row cs-dashboard-question-header" >
                <div className="mbsc-col" >
                    <div className="mbsc-row cs-dashboard-question-col-start">
                        {!Empty(teamtalk) &&
                            <Button disabled={!Empty(toast) || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => Dialog({view: 'dashboardQuestionUsers'})} >
                                <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faPlusMinus} />{width > 768 && '\u00A0'}
                            </Button>
                        }
                        {!Empty(teamtalk) && teamtalk.personality && teamtalk.voice &&
                            <div title={teamtalk.personality} style={{backgroundImage: Avatar()}} className={AvatarStyle({type: 'ai', user: 'ai'})} />
                        }
                        {!Empty(teamtalk) && teamtalk.users && teamtalk.users.length !== 0 && teamtalk.users.map(item => {
                            return (item.avatar ?
                                    <div title={item.firstName + ' ' + item.lastName} style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + item.avatar + "?d=robohash)"}} className={AvatarStyle({type: 'avatar', user: item.user})} />
                                    :
                                    <div title={item.firstName + ' ' + item.lastName} className={AvatarStyle({type: 'text', user: item.user})} >
                                        {item.firstName[0].toUpperCase()}{item.lastName[0].toUpperCase()}
                                    </div>
                            );
                        })}
                    </div>
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-question-col-end">
                        <Button disabled={messages === 0 || !Empty(toast) || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => ButtonRestart()} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faArrowRotateRight} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.w}</div>}
                        </Button>
                        {Empty(teamtalk) &&
                            <Button disabled={!Empty(toast) || messages === 0 || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => Dialog({view: 'dashboardQuestionSave'})} >
                                <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faBoxTaped} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.d}</div>}
                            </Button>
                        }
                        {Empty(teamtalk) &&
                            <Button disabled={!Empty(toast) || tabs.length < 2 || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => ButtonDelete()} >
                                <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faTrashCan} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.x}</div>}
                            </Button>
                        }
                        {!Empty(teamtalk) &&
                            <Button disabled={!Empty(toast) || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => ButtonLeave()} >
                                <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faArrowRightFromArc} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.e}</div>}
                            </Button>
                        }
                        <Button disabled={!Empty(toast)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => Dialog({view: 'dashboardQuestionSettings'})} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faGearCode} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-row cs-dashboard-question-main">
                <div className="mbsc-col cs-dashboard-question-body">
                    <CKEditor
                        editor={BalloonEditor}
                        config={{
                            toolbar: {
                                items: ['bold', 'italic', 'underline', 'subscript', 'superscript', 'removeFormat', '|', 'blockQuote', 'codeBlock'],
                                shouldNotGroupWhenFull: false
                            },
                            blockToolbar: ['bold', 'italic', '|', 'blockQuote', 'codeBlock'],
                            versionCheck: false,
                            plugins: [
                                Autosave,
                                BlockQuote,
                                BlockToolbar,
                                Bold,
                                Essentials,
                                Italic,
                                Paragraph,
                                RemoveFormat,
                                Subscript,
                                Superscript,
                                Underline,
                                CodeBlock
                            ],
                            licenseKey: 'GPL',
                            ui: {
                                poweredBy: {position:'inside', side:'right'}
                            }
                        }}
                        data={text}
                        onChange={(event, editor) => EditorUpdate(editor.getData())}
                        disableWatchdog={true}
                        onReady={EditorReady}
                    />
                </div>
            </div>
            {!loading &&
                <div className="mbsc-row cs-dashboard-question-footer">
                    <div className="mbsc-col" >
                        <div className="mbsc-row cs-dashboard-question-col-start">
                            <Button disabled={Boolean(!Empty(toast) || system)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={(ev) => ButtonWorkspace()} >
                                <div className="cs-dashboard-question-badge">
                                    <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faObjectsColumn} />
                                    {workspace.length > 0 && (
                                        <span className="cs-dashboard-question-badge-icon">{workspace.length}</span>
                                    )}
                                </div>
                                {width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.f}</div>}
                            </Button>
                        </div>
                    </div>
                    <div className="mbsc-col-auto">
                        <div className="mbsc-row cs-dashboard-question-col-end">
                            <Button disabled={!Empty(toast) || system || !text || text === '<p></p>'} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={(ev) => ButtonMessage()} >
                                <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faPaperPlane} /><div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.a}</div>
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {dialog.view === 'dashboardQuestionSettings' &&
                <DashboardQuestionSettings
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    configuration={configuration}
                    width={width}
                    toast={toast}
                    system={system}
                    setSystem={setSystem}
                    setModel={setModel}
                    model={model}
                    teamtalk={teamtalk}
                    setTeamtalk={setTeamtalk}
                />
            }
            {dialog.view === 'dashboardQuestionSave' &&
                <DashboardQuestionSave
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    setToast={setToast}
                    width={width}
                />
            }
            {dialog.view === 'dashboardQuestionUsers' &&
                <DashboardQuestionUsers
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setToast={setToast}
                    width={width}
                    teamtalk={teamtalk}
                    setTeamtalk={setTeamtalk}
                />
            }
            <Snackbar
                cssClass="cs-dashboard-question-snackbar mbsc-bold"
                isOpen={system}
                message={locale.dashboardQuestion.b}
                duration={false}
                color="primary"
                display="bottom"
                button={{
                    text: locale.dashboardQuestion.c,
                    action: () => {
                        ButtonSystem()
                    }
                }}
            />
        </>
    );
}

export default DashboardQuestion;
