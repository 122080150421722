import React from "react";
import '../styles/utilsIconFile.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFileImage,
    faFileCode,
    faFilePdf,
    faFile,
    faCircleThreeQuartersStroke,
    faChartPie,
    faChartScatter3d,
    faChartRadar,
    faChartSimple,
    faChartLine,
    faChartScatterBubble,
    faChartScatter,
    faTable,
    faFileCsv,
    faFileLines,
    faFontCase,
    faCode,
    faListCheck,
    faBooks,
    faMessageImage,
    faCircleCalendar,
    faCalendarClock
} from "@fortawesome/pro-duotone-svg-icons";

function IconFile(data) {

    if (data.type === 'empty') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faFile} />
    } else if (data.type === 'file' && data.subtitle && (data.subtitle.toLowerCase().includes('html') || data.subtitle.toLowerCase().includes('css') || data.subtitle.toLowerCase().includes('javascript') || data.subtitle.toLowerCase().includes('json') || data.subtitle.toLowerCase().includes('xml'))) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faFileCode} />
    } else if (data.type === 'file' && data.subtitle && data.subtitle.toLowerCase().includes('image') && !data.prompt) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faFileImage} />
    } else if (data.type === 'file' && data.subtitle && data.subtitle.toLowerCase().includes('image') && data.prompt) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faMessageImage} />
    } else if (data.type === 'file' && data.subtitle && data.subtitle.toLowerCase().includes('pdf')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faFilePdf} />
    } else if (data.type === 'file' && data.subtitle && data.subtitle.toLowerCase().includes('csv')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faFileCsv} />
    } else if (data.type === 'graph' && data.subtitle && data.subtitle.toLowerCase().includes('doughnut')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faCircleThreeQuartersStroke} />
    } else if (data.type === 'graph' && data.subtitle && data.subtitle.toLowerCase().includes('pie')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faChartPie} />
    } else if (data.type === 'graph' && data.subtitle && data.subtitle.toLowerCase().includes('polar')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faChartScatter3d} />
    } else if (data.type === 'graph' && data.subtitle && data.subtitle.toLowerCase().includes('radar')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faChartRadar} />
    } else if (data.type === 'graph' && data.subtitle && data.subtitle.toLowerCase().includes('bar')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faChartSimple} />
    } else if (data.type === 'graph' && data.subtitle && data.subtitle.toLowerCase().includes('line')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faChartLine} />
    } else if (data.type === 'graph' && data.subtitle && data.subtitle.toLowerCase().includes('bubble')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faChartScatterBubble} />
    } else if (data.type === 'graph' && data.subtitle && data.subtitle.toLowerCase().includes('scatter')) {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faChartScatter} />
    } else if (data.type === 'data-grid') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faTable} />
    } else if (data.type === 'text' && data.subtitle === 'document') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faFileLines} />
    } else if (data.type === 'text' && data.subtitle === 'editor') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faFontCase} />
    } else if (data.type === 'text' && data.subtitle === 'code') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faCode} />
    } else if (data.type === 'to-do') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faListCheck} />
    } else if (data.type === 'wikipedia') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faBooks} />
    } else if (data.type === 'calendar') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faCircleCalendar} />
    } else if (data.type === 'action') {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faCalendarClock} />
    } else {
        return <FontAwesomeIcon className="cs-utils-icon-file-icon" icon={faFile} />
    }
}

export default IconFile;
